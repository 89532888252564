<template>
  <div style="padding: 5px 20px; height: auto">
    <div class="modal-detalhes-cabecacho">
      <div class="mt-2 mb-2 camp-botao-voltar" @click="$emit('botao-voltar')">
        <!-- <b-icon-arrow-left-square font-scale="2.5" style="color: #808080" /> -->
        <div class="back-arrow-squared">
          <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
        </div>
      </div>
      <div class="d-flex">
        <b-button
          v-if="!['API_E', 'API_G', 'WHK'].includes(item.banco_id)"
          variant="primary"
          class="mr-2"
          @click="testarConexao"
          >Testar Conexão</b-button
        >
        <b-button
          v-if="item.sistema_obj.sis_visibilidade"
          variant="secondary"
          @click="$emit('botao-editar', item)"
          >Editar</b-button
        >
      </div>
    </div>

    <hr style="margin: 2px -20px 10px -20px" />
    <b-container>
      <b-row class="mt-3">
        <b-col>
          <span class="modal-detalhes-corpo-licen-secao-descricao">Status</span>
          <section style="display: flex; align-items: center">
            <status-components :ativo="status" />
            <span
              class="modal-detalhes-corpo-licen-secao-informacao ml-2"
              style="display: inline"
              >{{ status ? "Ativa" : "Desativa" }}</span
            >
          </section>
        </b-col>
        <b-col>
          <section class="modal-detalhes-corpo-licen-secao">
            <span class="modal-detalhes-corpo-licen-secao-descricao"
              >Criação</span
            >
            <span class="modal-detalhes-corpo-licen-secao-informacao">{{
              obterDataFormatada(item.sli_data_cadastro)
            }}</span>
          </section>
        </b-col>
        <b-col>
          <section class="modal-detalhes-corpo-licen-secao">
            <span class="modal-detalhes-corpo-licen-secao-descricao"
              >Última atualização</span
            >
            <span class="modal-detalhes-corpo-licen-secao-informacao">{{
              obterDataFormatada(item.sli_data_atualizacao)
            }}</span>
          </section>
        </b-col>
        <b-col>
          <section class="d-flex justify-content-between w-100">
            <section class="modal-detalhes-corpo-licen-secao">
              <span class="modal-detalhes-corpo-licen-secao-descricao"
                >Título licenciada</span
              >
              <span class="modal-detalhes-corpo-licen-secao-informacao">{{
                item.sli_titulo
              }}</span>
            </section>
          </section>
        </b-col>
        <b-col>
          <section class="modal-detalhes-corpo-licen-secao">
            <span class="modal-detalhes-corpo-licen-secao-descricao"
              >Usuário</span
            >
            <span class="modal-detalhes-corpo-licen-secao-informacao">{{
              item.sli_db_usuario
            }}</span>
          </section>
        </b-col>
        <b-col>
          <section class="modal-detalhes-corpo-licen-secao">
            <span class="modal-detalhes-corpo-licen-secao-descricao"
              >Senha</span
            >
            <div class="d-flex align-items-center">
              <b-input
                v-model="item.sli_db_senha"
                :type="esconderSenha ? 'password' : 'text'"
                class="licen-modal-senha"
                readonly
              />
              <div>
                <span
                  v-if="!esconderSenha"
                  class="display-eye fa fa-eye-slash"
                  @click="esconderSenha = true"
                ></span>
                <span
                  v-if="esconderSenha"
                  class="display-eye fa fa-eye"
                  @click="esconderSenha = false"
                ></span>
              </div>
            </div>
          </section>
        </b-col>
        <b-col>
          <section class="modal-detalhes-corpo-licen-secao">
            <span class="modal-detalhes-corpo-licen-secao-descricao"
              >Endereço</span
            >
            <span class="modal-detalhes-corpo-licen-secao-informacao">{{
              item.sli_db_dsn
            }}</span>
          </section>
        </b-col>
        <b-col>
          <section class="modal-detalhes-corpo-licen-secao">
            <span class="modal-detalhes-corpo-licen-secao-descricao"
              >Porta</span
            >
            <span class="modal-detalhes-corpo-licen-secao-informacao">{{
              item.sli_db_port
            }}</span>
          </section>
        </b-col>
        <b-col>
          <section class="modal-detalhes-corpo-licen-secao">
            <span class="modal-detalhes-corpo-licen-secao-descricao"
              >Sistema</span
            >
            <span class="modal-detalhes-corpo-licen-secao-informacao">{{
              item.sli_token
            }}</span>
          </section>
        </b-col>
      </b-row>
      <!-- <b-row>
        <span>HISTÓRICO</span>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
import StatusComponents from "./StatusComponents.vue";
import moment from "moment";
import "moment/locale/pt-br";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../api.links";

export default {
  name: "ModalDetalhesLicenciada",
  props: { item: Object },
  components: {
    StatusComponents
  },
  data() {
    return {
      esconderSenha: true,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {}
      }
    };
  },

  methods: {
    status() {
      return this.item.sli_status == "A" ? true : false;
    },
    obterDataFormatada(data) {
      return data
        ? moment(data, "YYYY-MM-DD hh:mm:ss")
            .locale("pt-br")
            .format("DD [de] MMMM[,] YYYY")
        : "-";
    },
    async testarConexao() {
      this.$emit("exibir-toast", {
        severity: "info",
        summary: "Testando a conexão com o sistema",
        detail:
          "Isso pode demorar uma pouco! Mas logo iremos informar o resultado.",
        life: 5000
      });
      let response = await ApiService.get(
        API_LINKS.sistemaLicenciadaTestarConexao,
        this.item.sistema_licenciada_id
      );
      let requisicao = response.data.requisicao;
      if (requisicao.sucesso) {
        let response = await ApiService.get(
          API_LINKS.sistemaLicenciadaRespostaTesteConexao,
          this.item.sistema_licenciada_id + "/" + requisicao.uid
        );
        requisicao = response.data.requisicao;
        if (requisicao.sucesso) {
          this.$emit(
            "exibir-toast",
            requisicao.sucesso
              ? {
                  severity: "success",
                  summary: "Teste de conexão bem sucedido!",
                  detail:
                    "Verificamos suas credenciais e tudo está funcionando corretamente.",
                  life: 7000
                }
              : {
                  severity: "error",
                  summary: "Erro no teste de conexão",
                  detail:
                    "Verificamos suas credenciais e não foi possível se conectar!.",
                  life: 7000
                }
          );
        }
      } else {
        this.$emit("exibir-toast", {
          severity: "error",
          summary: "Erro no teste de conexão",
          detail: "Não foi possível solicitar o teste de conexão.",
          life: 7000
        });
      }
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;600&display=swap");

.modal-detalhes-cabecacho {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.modal-detalhes-corpo-licen {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modal-detalhes-corpo-licen-secao {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 180px;
}

/* Texto menor */
.modal-detalhes-corpo-licen-secao-descricao {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
}

/* Texto maior */
.modal-detalhes-corpo-licen-secao-informacao {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.3rem;
}

.modal-detalhes-corpo-licen-texto-campanha {
  width: 70%;
}

.modal-detalhes-corpo-licen-status {
  display: block;
  margin-left: 20px;
}

.modal-detalhes-mensagem {
  width: 100%;
}

.modal-detalhes-mensagem-textarea {
  background-color: #fff;
  height: 200px;
  border-radius: 0px 20px 20px 20px;
  border: 1px solid #acacac;
  padding: 20px;
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.modal-detalhes-botao {
  margin: 1px;
}

h6,
h5,
h4,
h3,
h2,
h1 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.block {
  display: block;
}

.rounded-top-rigth {
  border-radius: 10px 0px 0px 0px;
}

.licen-modal-senha {
  width: 100%;
  height: 40px;
  border: none;
  margin: 0;
  padding: 0;
}

.camp-botao-voltar {
  color: #808080;
  transition: all 0.1s linear;
  cursor: pointer;
}

.camp-botao-voltar:hover {
  transform: scale(1.1);
}

.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
