<template>
  <div>
    <Toast position="top-right" />
    <div
      class="d-flex justify-content-center align-items-center h-75"
      v-if="carregando"
    >
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>
    <div v-else>
      <div>
        <!-- scrollable -->
        <b-table
          id="tabelaAgente"
          small
          :current-page="paginacao.paginaAtual"
          :per-page="paginacao.porPagina"
          :fields="fields"
          :items="itens"
          responsive="sm"
          sort-by="lic_status"
          :sort-desc="false"
          hover
        >
          <template #cell(lic_status)="data">
            <div class="camp-center mt-4 ml-4">
              <div
                :class="data.value == 'A' ? 'status-on' : 'status-off'"
              ></div>
            </div>
          </template>

          <template #cell(lic_descricao)="data">
            <div class="mt-3">
              {{ data.value }}
            </div>
          </template>

          <template #cell(lic_token)="data">
            <div class="mt-3">
              {{ data.value }}
            </div>
          </template>

          <template #cell(lic_data_cadastro)="data">
            <div class="mt-3 w-50">
              {{ obterDataFormatada(data.value) }}
            </div>
          </template>

          <template #cell(lic_versao)="data">
            <div class="mt-3 ml-3 w-50">
              {{ data.value }}
            </div>
          </template>

          <template #cell()="row">
            <i>{{ row.value }}</i>
            <b-dropdown
              no-caret
              variant="outline-secondary"
              id="dropdown-dropleft"
              dropleft
              class="border-0"
            >
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>
              <b-dropdown-item href="#" @click="editar(row.item)"
                >Editar</b-dropdown-item
              >
              <b-dropdown-item @click="apagar(row.item)"
                >Remover</b-dropdown-item
              >
              <b-dropdown-item @click="atualizarCredenciais(row.item)"
                >Atualizar credenciais</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex justify-content-center">
          <b-pagination
            v-model="paginacao.paginaAtual"
            :total-rows="paginacao.totalItens"
            :per-page="paginacao.porPagina"
            aria-controls="tabelaAgente"
            align="fill"
          ></b-pagination>
        </div>
      </div>
      <b-modal
        id="modal-cadastro-agente"
        ref="modal"
        :title="(eEdicao ? 'Edição' : 'Cadastro') + ' de agente'"
        cancel-title="Cancelar"
        :ok-title="eEdicao ? 'Atualizar' : 'Cadastrar'"
        @show="apagarFormModal"
        @hidden="apagarFormModal"
        @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Descrição"
            label-for="descricao-input"
            invalid-feedback="Descrição é necessária"
          >
            <b-form-input
              id="descricao-input"
              v-model="$v.cadastro.lic_descricao.$model"
              :state="validateState('lic_descricao')"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Status"
            label-for="status-select"
            invalid-feedback="Selecione um status"
          >
            <b-form-select
              id="status-select"
              v-model="$v.cadastro.lic_status.$model"
              :state="validateState('lic_status')"
              :options="['ATIVO', 'INATIVO', 'SUSPENSO']"
            >
            </b-form-select>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../api.links";
import Swal from "sweetalert2";
import Toast from "primevue/toast";
import moment from "moment";

export default {
  name: "IndexAgente",
  mixins: [validationMixin],
  components: { Toast },
  data() {
    return {
      cadastro: {
        lic_descricao: "",
        lic_status: ""
      },
      eEdicao: false,
      fields: [
        // { key: "status", label: "Status" },
        { key: "lic_status", label: "Online" },
        { key: "lic_descricao", label: "Nome do Agente" },
        { key: "lic_token", label: "Token" },
        { key: "lic_data_cadastro", label: "Data de criação" },
        { key: "lic_versao", label: "Versão" },

        // A virtual column made up from two fields
        { key: "opcoes", label: "Opções", class: "text-center" }
      ],
      itens: [],

      paginacao: {
        paginaAtual: 1,
        totalPaginas: 0,
        porPagina: 5,
        totalItens: 0
      },
      currentPage: 1,
      carregando: false,
      listaLicencasOnline: []
    };
  },
  methods: {
    mostrarModal(editar = false) {
      this.eEdicao = editar;
      this.$nextTick(() => {
        if (editar == false) {
          this.cadastro.lic_descricao = null;
          this.cadastro.lic_status = null;
        }
        this.$bvModal.show("modal-cadastro-agente");
      });
    },
    apagarFormModal() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.$v.cadastro.$touch();
      if (this.$v.cadastro.$anyError) {
        return;
      }

      this.cadastro.lic_status = this.cadastro.lic_status[0];
      ApiService.post(
        API_LINKS.licenca +
          (this.eEdicao ? "/" + this.cadastro.licenciada_id : ""),
        { licenca: this.cadastro }
      ).then((response) => {
        if (response.data["erro"] != "undefined") {
          this.exibirToast({
            severity: "success",
            summary: "Agente " + (this.eEdicao ? "atualizado" : "cadastrado"),
            detail: "Você já pode usar o agente nos seus sistemas!",
            life: 7000
          });
        }
        this.eEdicao = false;
        this.atualizarTabela();
      });

      this.$nextTick(() => {
        this.$bvModal.hide("modal-cadastro-agente");
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.cadastro[name];
      return $dirty ? !$error : null;
    },
    editar(item) {
      this.eEdicao = true;
      this.itemSelecionado = item;
      this.cadastro = { ...this.itemSelecionado };
      this.cadastro.lic_status =
        this.itemSelecionado.lic_status == "A" ? "ATIVO" : "INATIVO";
      this.mostrarModal(true);
    },
    async apagar(item) {
      this.itemSelecionado = item;
      Swal.fire({
        title:
          'Você deseja remover o agente "' +
          this.itemSelecionado.lic_descricao +
          '"?',
        text: "Não será possivel reverter essa ação! Você pode desativar o agente sem precisar removê-lo.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero remover!",
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await ApiService.delete(
            API_LINKS.licenca + "/" + this.itemSelecionado.licenciada_id
          );
          Swal.fire(
            "Removido",
            "Seu agente foi removido com sucesso!",
            "success"
          );
          this.atualizarTabela();
        }
      });
    },
    async atualizarTabela() {
      this.carregando = true;
      let lic = await ApiService.get(API_LINKS.licenca, "", false);
      let licOn = await ApiService.get(API_LINKS.licencasOnlines, "", false);

      this.paginacao.totalItens = lic.data.licenciadas.length;
      this.paginacao.totalPaginas = Math.ceil(
        this.paginacao.total / this.paginacao.porPagina
      );

      this.listaLicencasOnline = licOn.data.agentes.map((item) => item[0]);

      this.itens = lic.data.licenciadas;
      this.itens.map((item) => {
        item.online = this.listaLicencasOnline.includes(item.lic_token); // Verifica se o agente está online
      });
      this.carregando = false;
    },
    async atualizarCredenciais(item) {
      this.exibirToast({
        severity: "info",
        summary: "Atualizando credenciais",
        detail:
          "Estamos entrando em contato com o agente para atualizar suas credenciais!",
        life: 4000
      });
      try {
        let response = await ApiService.get(
          API_LINKS.forcarAtualizarCredenciais,
          item.licenciada_id,
          false
        );
        this.exibirToast({
          severity: "success",
          summary: "Suas credenciais foram atualizadas!",
          detail:
            "Atualizamos as informações de conexão contidas no agente! Isso pode demorar um pouco para surtir efeito.",
          life: 7000
        });
      } catch (error) {
        this.exibirToast({
          severity: "error",
          summary: "Erro em atualizar suas credenciais",
          detail:
            "Aconteceu um erro durante a tentativa de conexão com o agente! Tente novamente mais tarde.",
          life: 7000
        });
      }
    },
    exibirToast(toast) {
      this.$toast.removeAllGroups();
      this.$toast.add(toast);
    },
    obterDataFormatada(data) {
      return data
        ? moment(data, "YYYY-MM-DD hh:mm:ss")
            .locale("pt-br")
            .format("DD [de] MMMM[,] YYYY")
        : "-";
    }
  },
  validations: {
    cadastro: {
      lic_descricao: {
        required
      },
      lic_status: {
        required
      }
    }
  },
  mounted() {
    this.atualizarTabela();
  }
};
</script>

<style>
.status-on {
  background-color: rgb(97, 191, 3);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
.status-off {
  background-color: rgb(214, 21, 21);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
</style>
