<template>
  <div class="card h-100">
    <Toast position="top-right" />
    <div class="card-header p-6">Configurações extras</div>
    <div class="card-body p-3 d-flex flex-column justify-content-between">
      <b-table
        id="tabela-configuracoes"
        striped
        hover
        :per-page="perPage"
        :current-page="currentPage"
        :items="itensOpcoes"
        :fields="fields"
      >
        <template #cell(_ativo)="data">
          <div class="mt-2 ml-2" style="width: 50px">
            <b-badge
              pill
              :variant="data.item.sic_valor != '' ? 'success' : 'danger'"
              class="p-1"
              >{{ data.item.sic_valor != "" ? "ATIVO" : "INATIVO" }}</b-badge
            >
          </div>
        </template>

        <template #cell(sic_campo)="data">
          <div
            class="d-flex mt-3"
            style="width: 150px"
            v-b-tooltip.hover
            :title="data.value.sic_descricao"
          >
            {{ data.value }}
          </div>
        </template>

        <template #cell(sic_valor)="data">
          <div class="d-flex">
            <b-form-input
              v-model="data.item['sic_valor']"
              style="width: 100%"
            />
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-if="itensOpcoes.length > perPage"
          v-model="currentPage"
          :total-rows="itensOpcoes.length"
          :per-page="perPage"
          aria-controls="tabela-configuracoes"
        ></b-pagination>
      </div>
    </div>
    <div class="card-footer p-2">
      <div class="d-flex justify-content-end">
        <b-button @click="cancelar">Cancelar</b-button>
        <b-button variant="success" class="ml-2" @click="salvar"
          >Salvar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../api.links";
import Toast from "primevue/toast";

export default {
  props: {
    licenciada: Object
  },
  components: { Toast },
  data() {
    return {
      adicionaDDD: false,
      fields: [
        { key: "_ativo", label: "Status", class: "text-center" },
        { key: "sic_campo", label: "Campo" },
        { key: "sic_valor", label: "Valor" }
      ],
      itensOpcoes: [],
      itensOpcoesClone: [],
      perPage: 6,
      currentPage: 1
    };
  },
  methods: {
    delete(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to proceed?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //callback to execute when user confirms the action
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      });
    },
    async salvar() {
      if (this.objetosMudados().length == 0) {
        this.exibirToast({
          severity: "info",
          summary: "Propriedades do sistema",
          detail: "Você não fez nenhuma alteração nas propriedades!",
          life: 5000
        });
        this.$emit("botao-voltar");
        return;
      }
      let listaEnvio = this.itensOpcoes;
      let listaEnvioFormatada = [];
      listaEnvio.forEach((item) => {
        if (item.sic_valor != "") {
          listaEnvioFormatada.push({
            sistema_configuracao_id: item.sistema_configuracao_id,
            sic_valor: item.sic_valor
          });
        }
      });

      listaEnvioFormatada = {
        configuracoes: {
          sistema_licenciada_id: this.licenciada.sistema_licenciada_id,
          configuracoes: listaEnvioFormatada
        }
      };
      try {
        let response = await ApiService.post(
          API_LINKS.sistemaLicenciadaConfiguracoes,
          listaEnvioFormatada
        );
        this.carregarDados();
        this.exibirToast({
          severity: "success",
          summary: "Propriedades do sistema",
          detail:
            "Todas as propriedades do sistema foram atualizadas com sucesso!",
          life: 5000
        });
      } catch (error) {
        this.exibirToast({
          severity: "error",
          summary: "Propriedades do sistema",
          detail: "Não foi possível atualizar as propriedades do sistema!",
          life: 5000
        });
      }
      this.$emit("botao-voltar");
    },
    cancelar() {
      let listObj = this.objetosMudados();
      if (listObj.length != 0) {
        this.$emit("botao-voltar"); // TODO: Adicionar um popup para evitar que a pessoa tenha alterado as informações e esqueça de salvar
      } else {
        this.$emit("botao-voltar");
      }
    },
    objetosMudados() {
      let listaEnvio = [];
      for (let i = 0; i < this.itensOpcoes.length; i++) {
        if (
          this.itensOpcoes[i].sic_valor != this.itensOpcoesClone[i].sic_valor
        ) {
          listaEnvio.push(this.itensOpcoes[i]);
        }
      }
      return listaEnvio;
    },
    exibirToast(toast) {
      this.$toast.removeAllGroups();
      this.$toast.add(toast);
    },
    async carregarDados() {
      let responseConfiguracoesAtivas = await ApiService.get(
        `${API_LINKS.sistemaLicenciadaConfiguracoes}/ativos/${this.licenciada.sistema_licenciada_id}`
      );
      let responseConfiguracoesDisponiveis = await ApiService.get(
        `${API_LINKS.sistemaLicenciadaConfiguracoes}/disponiveis/${this.licenciada.sistema_licenciada_id}`
      );

      let opcoesAtivas = responseConfiguracoesAtivas.data.configuracoes;
      let opcoesDisponiveis =
        responseConfiguracoesDisponiveis.data.configuracoes;

      // Junta a duas listas que contem, ou não, as propriedades
      for (let i = 0; i < opcoesDisponiveis.length; i++) {
        if (
          !opcoesAtivas.find(
            (item) =>
              item.sistema_configuracao_id ==
              opcoesDisponiveis[i].sistema_configuracao_id
          )
        ) {
          opcoesAtivas.push(opcoesDisponiveis[i]);
        }
      }

      this.itensOpcoes = Object.values(opcoesAtivas);
      this.adicionaAtributosControle();
      this.itensOpcoesClone = JSON.parse(JSON.stringify(this.itensOpcoes));
    },
    adicionaAtributosControle() {
      this.itensOpcoes.forEach((item) => {
        if (item?.sic_valor == undefined) {
          item.sic_valor = "";
          item._ativo = "nao_ativo";
        } else {
          item._ativo = "ativo";
        }
      });
    }
  },
  mounted() {
    this.carregarDados();
  }
};
</script>

<style></style>
